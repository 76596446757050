<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#F0F"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{$t('message.rech_th0')}}</b-th>
          <b-th class="d-none d-md-table-cell">{{$t('message.rech_th1')}}</b-th>
          <b-th class="d-none d-sm-table-cell">{{$t('message.rech_th2')}}</b-th>
          <b-th v-show="retfee">{{$t('message.rech_th3')}}</b-th>
          <b-th v-show="!retfee">{{$t('message.rech_th4')}}</b-th>
          <b-th>{{$t('message.rech_th5')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.rech_tt6')" v-show="retfee">{{$t('message.rech_th6')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.rech_tt7')" v-show="!retfee">{{$t('message.rech_th7')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.rech_tt8')">{{$t('message.rech_th8')}}</b-th>
          <b-th class="d-none d-sm-table-cell">{{$t('message.rech_th9')}}</b-th>
        </b-tr>        
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td v-b-tooltip.hover :title="item.nowmobile">{{item.mobile}}</b-td>
          <b-td class="d-none d-md-table-cell">{{item.paydate}}</b-td>
          <b-td class="d-none d-sm-table-cell">{{item.paytime}} <b-icon icon="arrow-bar-up" v-show="(retfee==0 && index==1 && (filteropenid || searchmobile))" @click="upme(item.openid,item.cretime)"></b-icon></b-td>
          <b-td>{{item.cretmstr}}</b-td>
          <b-td>{{item.money}}</b-td>
          <b-td>{{item.balance}}
            <b-button v-show="workingid==-1" size="sm" variant="outline-secondary" class="mr-2 d-none d-sm-table-cell" @click="setme(index)" v-if="!retfee">
              送
            </b-button>
          </b-td>
          <b-td colspan="2" v-show="workingid==index">
                <b-input-group size="sm">
                  <b-form-input v-model="thefreemoney"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="info" @click="freemoney(item)">赠送</b-button>
                  </b-input-group-append>
                </b-input-group>
          </b-td>
          <b-td v-if="workingid!=index">{{item.payseq}}</b-td>
          <b-td v-if="workingid!=index" class="d-none d-sm-table-cell">
            <b-button size="sm" variant="outline-info" class="mr-2" :to="{path:'useroper',query:{openid:item.openid,tm:new Date().getTime()}}">
              <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>{{$t('message.btn_user_visit')}}
            </b-button>
            <img :src="item.headimgurl" class="wxhead" @click="showbig(item.headimgurl)"/> {{item.nickname}}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="gem"></b-icon> 用户充值或退款记录</h4>
        </template>
        <b-card-text>
          <p>
            暂时没有数据<br/><br/>
            扫码-绑手机-充值-充电<br/>
            充电页面支持一键充值<br/>
            <b-form inline class="mt-4"><b-alert :show="admnodft">{{$t('message.cfmdefault')}}</b-alert></b-form>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="imgshow xnshadow">
        <img :src="bigheadimgurl" class="w-100"/>
      </div>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group
        buttons
        v-model="retfee"
        class="mr-4 mb-4"
        :options="retopts"
        @change="retchange"
        button-variant="outline-primary"
      ></b-radio-group>
      <b-input-group class="mr-4 mb-4">
        <b-form-input :placeholder="$t('message.entermobile')" v-model="searchmobile" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-button-group class="mb-4 d-none d-lg-block" style="width:580px;">
          <b-input-group>
              <b-form-datepicker placeholder="起始日期(包含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-start" v-model="datstart"></b-form-datepicker>
              <b-form-datepicker placeholder="结束日期(不含)" menu-class="w-100" calendar-width="100%" locale="zh" start-weekday="1" hide-header="true" label-help="" weekday-header-format="narrow" id="datepicker-end"  v-model="datend"></b-form-datepicker>
              <b-input-group-append>
                  <b-button variant="info" @click="download" :disabled="clicked">
                      <span v-if="clicked"><b-icon variant="danger" icon="circle-fill" animation="throb" scale="4" font-scale="1" class="mr-2"></b-icon>下载</span>
                      <span v-else><b-icon icon="cloud-download" scale="1" font-scale="1" class="mr-2"></b-icon>下载</span>
                  </b-button>
              </b-input-group-append>
          </b-input-group>
      </b-button-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  import { QRSITE } from '@/config'
  export default {
    name: 'recharge',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        workingid: -1,
        thefreemoney: 0,
        filteropenid: this.$route.query.openid,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        bigheadimgurl: '',
        admnodft:false,
        searchmobile:'',
        retfee:0,
        retopts:[{text:this.$t('message.btn_chargerecod'),value:0},{text:this.$t('message.btn_refundrecod'),value:1}],
        items: [],
        pageid:0,
        datstart:'',
        datend:'',
        clicked:false,
        timeoutcnts:0,
        genrated:false,
        newpagestart:[]
      };
    },
    methods: {
      retchange($event) {
        this.workingid = -1;
        this.searchmobile='';
        this.retfee = $event;
        this.pageid = 0;
        this.fetchData();
      },
      search() {
        let mo = parseInt(this.searchmobile);
        if (this.searchmobile.length>0 && (this.searchmobile.length<4 || isNaN(mo))) {
          this.errormsg = '无效的手机号，最少4位数字';
          this.modalshow = true;
        }else{
          this.pageid = 0;
          this.fetchData();
        }
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      fetchData() {
        this.isloading = true;
        let user = JSON.parse(localStorage.getItem('user'));
        let dftapp = localStorage.getItem('dftapp');
        if (user.isadmin && !dftapp) {
          this.admnodft = true;
        } else {
          this.admnodft = false;
        }
        let w = window.innerWidth;
        let h = window.innerHeight;
        let token = localStorage.getItem('token');
        let theapi = '/prepays?tm='+new Date().getTime()+'&token='+token+'&retfee='+this.retfee+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h;
        if (this.filteropenid) {
          theapi = theapi + '&openid='+this.filteropenid;
        } else {
          theapi = theapi + '&mobile='+this.searchmobile;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        this.axios.get(theapi).then(axresp => {
          this.isloading = false;
          if (axresp.status==200) {
            let axdata = axresp.data;
            this.items = axdata.Items;
            if (axdata.LastEvaluatedKey) {
              if (this.newpagestart.length<=this.pageid) {
                this.newpagestart.push(axdata.LastEvaluatedKey);
              } else {
                this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
              }
            }
          } else {
            this.items = [];
          }
        });
      },
      async download(){
          if (this.datend=='') this.datend = new Date().format('yyyy-MM-dd');
          if (this.datstart=='') {
            this.modalmsg = '开始日期不能为空；<br/>不填结束日期表示截止今日0点；<br/>日期跨度不能大于31天；<br/>查询耗时较长，请耐心等待；';
            this.modalshow = true;
          } else {
            let startDate = new Date(Date.parse(this.datstart.replace(/-/g,'/')));
            let endDate = new Date(Date.parse(this.datend.replace(/-/g,'/')));
            let diff = (endDate.getTime()-startDate.getTime())/86400000;
            if (diff<0) {
                this.modalmsg = '开始日期不能晚于结束日期';
                this.modalshow = true;
            } else if (diff>31){
                this.modalmsg = '下载日期跨度不能超过31天';
                this.modalshow = true;
            } else {
                this.clicked = true;
                let token = localStorage.getItem('token');
                let nowdate = new Date().format('yyyy-MM-dd');
                let resulturl = QRSITE+'/qr/excel7/'+token+'_'+this.datstart+'_'+this.datend+'_'+nowdate+'.xlsx';
                let hdresp = undefined;
                try{
                    hdresp = await this.axios.head(resulturl);
                }catch(e){
                    if (e) hdresp = undefined;
                }
                if (hdresp && hdresp.status==200) {
                    this.clicked = false;
                    this.genrated = false;
                    window.location.href = resulturl;
                } else {
                    if (this.genrated==false) {
                        let today = new Date().format('yyyy-MM-dd');
                        let downdone = localStorage.getItem('downdone7');
                        if (downdone && downdone==today) {
                            this.modalmsg = '该功能每天限制运行一次，你已经运行过了，请明天再来。';
                            this.modalshow = true;
                            this.clicked = false;
                        } else {
                            var msg = '统计下载每天限运行一次，本次运行后今天将无法再次启动，建议起止日期设置为每月1日，你真的要现在运行吗？';
                            if (confirm(msg)) {
                                localStorage.setItem('downdone7', today);
                                this.genrated = true;
                                let theapi = '/prepaysdown?tm='+new Date().getTime()+'&token='+token+'&datstart='+this.datstart+'&datend='+this.datend;
                                this.axios.get(theapi).then(() => {});
                                this.modalmsg = '查询一个月需要10分钟或更长时间；<br/>本功能每日限制执行一次；<br/>建议每次查询1日到下月1日；<br/>查询时间为选择日的0点，所以不含结束当天；<br/>查询的时间间隔最大为31天；<br/>本查询比较费时，请耐心等待；<br/>请不要频繁反复使用本功能；<br/>下载后请妥善保存文件；<br/>如果长时间没有结果，请刷新页面重新查询；<br/>结果返回前光标会一直闪；';
                                this.modalshow = true;
                            } else {
                                this.clicked = false;
                            }
                        }
                    }
                    if (this.clicked) {
                        this.timeoutcnts++
                        if (this.timeoutcnts<3) {
                            setTimeout(this.download, 10000);
                        } else {
                            this.clicked = false;
                            this.modalmsg = '后台正在进行数据统计汇总<br/>10分钟后选择相同的日期下载数据';
                            this.modalshow = true;
                        }
                    }
                }
            }
          }
      },
      showbig(url) {
        this.bigheadimgurl = url;
        this.maskenabled = true;
      },
      closemask() {
        this.maskenabled = false;
      },
      setme(index) {
        this.workingid = index;
      },
      freemoney(itm) {
        this.workingid = -1;
        let token = localStorage.getItem('token');
        let theapi = '/freemoney?tm='+new Date().getTime()+'&token='+token+'&appid='+itm.appid+'&openid='+itm.openid+'&money='+this.thefreemoney;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status==200) {
            this.fetchData();
          }
        });
      },
      upme(openid,cretime) {
        let token = localStorage.getItem('token');
        let theapi = '/ppupme?tm='+new Date().getTime()+'&token='+token+'&cretime='+cretime+'&openid='+openid;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status==200) {
            this.fetchData();
          }
        });
      },
    }
  }
</script>
